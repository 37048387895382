import type { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import Button from '../components/Button'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { City } from '../interfaces/city.interface'
import { Pagination } from '../interfaces/pagination.interface'
import React from 'react'
import { useRouter } from 'next/router'

type PageProps = {
  cities: City[]
  pagination: Pagination
}

const Home: NextPage<PageProps> = ({ cities, pagination }) => {
  const router = useRouter()

  return (
    <div className="text-gray-900">
      <h1 className="mt-10 ml-10 mr-10 text-2xl text-center leading-normal">
        Vereinsfinder
      </h1>

      <div className="text-center text-sm font-medium mt-10">
        Wähle deine Stadt
      </div>

      <div>
        {cities.map((city: City) => (
          <Link key={city.slug} href={`clubs/${city.slug}`}>
            <a>
              <div className="border shadow mt-4 p-4 rounded">{city.title}</div>
            </a>
          </Link>
        ))}
      </div>

      <div className="flex flex-row flex-wrap gap-4 mt-5 justify-center items-stretch">
        {[...Array(pagination.totalPages + 1).keys()].slice(1).map((page) => (
          <Link
            key={page}
            href={{
              pathname: router.pathname,
              query: { ...router.query, page },
            }}
          >
            {page === pagination.currentPage ? (
              <span className="p-2 pl-4 pr-4 rounded text-gray-600">
                <span>{page}</span>
              </span>
            ) : (
              <a className="border p-2 pl-4 pr-4 rounded">
                <span>{page}</span>
              </a>
            )}
          </Link>
        ))}
      </div>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  const page = query?.page || 1

  const search = new URLSearchParams({
    page: `${page}`,
    limit: '10',
  }).toString()

  const res = await fetch(`${process.env.LISTINGS_API_URL}/cities?${search}`)

  const body = await res.json()

  return {
    props: {
      cities: body.items,
      pagination: body.meta,
    },
  }
}

export default Home
